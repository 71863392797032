import { Navbar, Nav, Row, Col, Container } from "react-bootstrap"
import logo from "../../assets/logo-shaval.gif"
import "./Menubar.css"

export const Menubar = () => {

    return   <Container fluid>
        <Navbar expand="lg" collapseOnSelect id="navbar">
            <Row className="justify-content-around">
                <Col lg={4} md={4} sm={12} xs={12}>
                    <Navbar.Brand href="#home">
                        <img src={logo} alt="Shanna et valentin" id="navbar-logo"/>
                    </Navbar.Brand>
                </Col>

                <Nav  className="me-auto" id="navbar-nav" >
                        <Col md={3} sm={3} xs={3}>
                            <Nav.Link href="#home" >Home</Nav.Link>
                        </Col>
                        <Col sm={3} xs={3} >
                            <Nav.Link href="#mairie">Mairie</Nav.Link>
                        </Col>
                        <Col sm={3} xs={3}>
                            <Nav.Link href="#soiree">Soiree</Nav.Link>
                        </Col>
                        <Col sm={3} xs={3}>
                            <Nav.Link href="#reponse">Réponse</Nav.Link>
                        </Col>
                </Nav>
            </Row>
        </Navbar>
    </Container>
}