import "./Introduction.css"
import logo from "../../assets/logo-shaval3.png" 
import shavalIntro from "../../assets/intro.jpg"
import { Col, Button, Container } from "react-bootstrap"
import { useEffect, useTransition } from "react"




export const Introduction = (props) => {
    const fadeInTitle = ()=>{
        const elem = document.getElementById("introduction-title")
        elem.animate([
            {},
            { opacity : 1 }
          ], {
            duration : 1500}
            ).finished.then(()=>{
              elem.style.opacity = 1
              bounceIn()
            })
    }
    const bounceIn = ()=>{
        const elem = document.getElementById("introduction-infos-button")
        elem.animate([
            {},
            { opacity : 1 }
          ], {
            duration : 1500}
            ).finished.then(()=>{
              elem.style.opacity = 1
            })
    }


    
    return <Container fluid id="introduction" onLoad={()=>{fadeInTitle()}}>
            {/* <Col>
                <img id="introduction-infos-pic" src={shavalIntro} alt="Shana et Valentin" />
            </Col> */}
        <article id="introduction-infos">

            <br></br>
            <Col>
                <h4>Le mariage de </h4>
                <h3 id="introduction-title">Shana & Valentin</h3>
            </Col>
            <br></br>
            <Col>
                <img src={logo} alt="logo" style={{ width: 50 + "px", height: "auto" }}/>
            </Col>
            <br></br>
            <Col>
                <Button id="introduction-infos-button" onClick={()=>{props.onClickOpen()}}>OUVRIR L'INVITATION</Button>
            </Col>
        </article>
    </Container>
}