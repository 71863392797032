import { Button, Col, Container, Row } from "react-bootstrap"
import "../Information.css"

import logo from "../../../assets/logo-shaval3.png"
import hebreux from "../../../assets/hebreux-1.png"

import React, { useState } from "react"


window.onload = function(){
	setTimeout(function () {
		var x = document.querySelectorAll(".yourNexthigherObject");
		for (var i = 0; i < x.length; i++) {
			x[i].style.display = "block";
			x[i].style.overflowX = "hidden";
		}
	}, 500);
}

const Soiree = () => {
    return( <Container className="justify-content-center" id="soiree">
        <Row className="justify-content-around" id="houppa">
            {/* <Col md={"auto"}> */}
                <Col  md="auto" xs="auto" className="titre"  data-aos="zoom-in"  data-aos-duration="2000">
                    La Houppa
                </Col>
            {/* </Col> */}
        </Row>
        <Row className="justify-content-around">
            <Col md="auto" xs="auto">
                <img   src={hebreux} alt="" className="hebreux-phrase"/>
            </Col>
        </Row>
        <Row className="justify-content-between  align-items-center" >
            <Col md={6} sm={6} xs={6} className="famille" data-aos="fade-in" data-aos-duration="500">
                    Madame et Monsieur<br></br>
                    Daisy et Bruno GENNARI<br />
                    Madame Zohara ELBAZ<br /><br />
            {/* </Col>
            <Col md={6} xs={6} className="famille" data-aos="fade-in" data-aos-duration="500"> */}
                    Madame et Monsieur <br />Kathy et Maurice ELBAZ
            </Col>
            {/* <Col md={6} xs={6} className="famille ">
            </Col> */}
        {/* </Row>
        <Row className="justify-content-between "> */}
            <Col md={6} xs={6} className="yourNexthigherObject">
                <div className="famille" data-aos="fade-in" data-aos-duration="500">
                    Madame et Monsieur <br />Marie-France et Yves OUAKI
                </div>
            </Col>
        </Row>
        <Row className="justify-content-around logo">
            <img src={logo} alt="logo" style={{ width: 50 + "px", height: "auto" }}  data-aos="zoom-in"  data-aos-duration="1000"/>
        </Row>
        <Row className="justify-content-around message">
            <Col md="auto" xs={10}  data-aos="zoom-in" data-aos-duration="2000">
                <p>
                    Ont l'immense joie de vous faire part du mariage de leurs petits-enfants et enfants
                </p>
            </Col>
        </Row>
            <Row className="justify-content-around mairie" >
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        Shana & Valentin
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around message">
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        Seraient honorés de votre présence à la cérémonie religieuse qui sera célébrée
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around infos">
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        Le dimanche 31 Mars 2024 à 17h
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around infos" >
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        au Manoir des Cygnes<br />
                        150 les berges de Seine<br />
                        91830, Le Coudray-Montceaux
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around message">
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        {/* Une réception suivra au */}
                        La cérémonie sera suivie de la réception
                    </p>
                </Col>
            </Row>
            
        <Row className="justify-content-center solenel">
            <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                <p>
                    "En ayant toujours une douce pensée et une grande reconnaissance pour nos grands-parents qui veillent sur nous et qui nous accompagneront sous la Houppa."
                    <br></br>
                    <br></br>
                    Monsieur Amram ELBAZ<br></br>
                    Madame Marie et Monsieur Marcel PRADES<br></br> 
                    Madame Georgette et Monsieur Joseph OUAKI.
                </p>
            </Col>
        </Row>
        
        <Row className="justify-content-around">
            <Col className="solenel-honneur" md="auto"  xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                <Button  className="itinéraire" href="https://ul.waze.com/ul?place=ChIJH3jbTGfm5UcRC582XvsjTdk&ll=48.56660840%2C2.50071120&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">Itinéraire</Button>
            </Col>
        </Row>
    </Container>)
}

export default Soiree