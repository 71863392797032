import { Row, Container, Col } from "react-bootstrap"
import "./Header.css"
import { CountdownDisplay } from "../countdown/CountdownDisplay"

import shaval from "../../assets/shaval5.jpg"

export const Header = () => {
    return <Container fluid id="header">
        <img id="header-pic" src={shaval}/>
        <Row id="header-infos">
            <Col id="header-infos-title" >
                <br></br>
                <Row className="justify-content-center">
                    <h3>Shana & Valentin</h3>
                </Row>
                <Row className="justify-content-center">
                    <h4>Le Dimanche 31 Mars 2024</h4>
                </Row>
                <br></br>
            </Col>
        </Row>
        <CountdownDisplay/>
    </Container>
}