import { useMemo } from "react"
import { useCountdown } from "../../tools/countdown"
import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
export const CountdownDisplay = () =>{
    const [val, setVal] =  useState()
    let date = "2024/03/31"

    let va = useCountdown(date)

    return <div fluid id="countdown-header">
        <Row className=" justify-content-center">
            <Col sm={3} xs={3} className="display-date align-items-center ">
                <Col >
                    {va[0]} 
                    <p className="display-date-p ">
                        jours
                    </p>
                </Col>
            </Col>
            <Col sm={3} xs={3} className="display-date  align-items-center  justify-content-center">
                <Col>
                    {va[1]}
                    <p className="display-date-p ">
                        heures
                    </p>
                </Col>
            </Col>
            <Col  sm={3} xs={3} className="display-date  align-items-center ">
                <Col>
                    {va[2]}
                    <p className="display-date-p ">
                        minutes
                    </p>
                </Col>
            </Col>
            <Col  sm={3} xs={3} className="display-date align-items-center justify-content-center">
                <Col className="align-item-center">
                    {va[3]}
                    <p className="display-date-p ">
                        secondes
                    </p>
                </Col>
            </Col>
        </Row>
    </div>

}