import { Button, Col, Container, Row } from "react-bootstrap"
import React from "react"
import "../Information.css"

import logo from "../../../assets/logo-shaval3.png"

const Mairie = () => {
    return(
        <Container fluid className="justify-content-center" id="mairie">
        <Row   className="justify-content-around titre">
            <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="2000">
                <div>La Mairie</div>
            </Col>
        </Row>
        <Row  className="justify-content-around mairie">
            <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="2000">
                <p>Shana & Valentin</p>
            </Col>
        </Row>
        <Row  className="justify-content-around">
            <Col md="auto" xs="auto"  data-aos="zoom-in"  data-aos-duration="1000">
                <p>se diront</p>
            </Col>
        </Row>
        <Row  className="justify-content-around">
            <Col md="auto" xs="auto" className="importante-oui" data-aos="zoom-in"  data-aos-duration="2000">
                <p>"Oui"</p>
            </Col>
        </Row>
        <Row className="justify-content-around infos" >
            <Col md="auto" data-aos="zoom-in"  data-aos-duration="1500">
                <p>Le Lundi 18 Mars 2024 à 13h30</p>
            </Col>
        </Row>
        <br></br>
        <Row  className="justify-content-around infos">
            <Col md="auto" style={{textAlign:"center"}} xs="auto" data-aos="zoom-in"  data-aos-duration="1500">
                <span className="">A la Mairie de Charenton</span>
            </Col>
        </Row>
        <Row className="justify-content-around infos">
            <Col md="auto"  xs="auto" data-aos="zoom-in"  data-aos-duration="1500">
                <p>48 rue de Paris <br></br>94220 Charenton-le-pont</p>
            </Col>
        </Row>
        <br></br>
        
        <Row className="justify-content-around logo">
            <img src={logo} alt="logo" style={{ width: 50 + "px", height: "auto" }}  data-aos="zoom-in"  data-aos-duration="1000"/>
        </Row>
        <br></br>
        <Row className="justify-content-around">
            <Col className="solenel-honneur" md="auto"  xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                <p>Un vin d'honneur suivra le mariage civil</p><br></br>
                <Button  className="itinéraire" href="https://ul.waze.com/ul?preview_venue_id=1573352.15864594.15602809&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">Itinéraire</Button>
            </Col>
        </Row>
        </Container>
    )

}

export default Mairie