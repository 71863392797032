import { Container, Row } from "react-bootstrap"
import Mairie from "./mairie/Mairie"
import shaval2 from "../../assets/shaval-intro.png"
import Soiree from "./soiree/Soiree"

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

AOS.init()

export const Information = () => {

    return <Container fluid>
            <Mairie/>
            <Row className="justify-content-center">
                <img id="pic-intro" src={shaval2} alt="La soirée" style={{width:"auto", height : 300+"px"}}/>
            </Row>
            <Soiree/>
        </Container>
}