import './App.css';
import "./components/introduction/Introduction.css"
import "./components/countdown/CountdownDisplay.css"
import "./components/header/Header.css"
import "./components/navbar/Menubar.css"
import "./components/formulaire/Formulaire.css"

import { Container } from "react-bootstrap"

import { Introduction } from "./components/introduction/Introduction"

import { useState } from "react";
import Aos from "aos";
import { Menubar } from './components/navbar/Menubar';
import { Header } from './components/header/Header';
import { Formulaire } from './components/formulaire/Formulaire';
import { Information } from './components/information/Information';



const onClickOpen = () => {
  const elem = document.getElementById("introduction")
  elem.animate([
    {},
    { transform : "translateY(-100vh)" }
  ], {
    duration : 1500}
    ).finished.then(()=>{
      elem.style.display = "none"
    })
    
}

function App() {
  const [show, setShow] = useState(false)
  Aos.init()

  return (
    <>
    
      <Introduction onClickOpen={()=>{
          onClickOpen()
          setShow(true)
        }}/>
        { show &&  <Menubar/>}
        
        { show &&  <Header/>}
        { show &&  <Information/>}
        { show &&  <Formulaire/>}
    </>
  )
}

export default App;
