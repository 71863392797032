import React, { useEffect, useState } from "react"

import {Form,  FloatingLabel, Row, Col, Button, Container, Modal } from "react-bootstrap"
import axios from "axios"

export const Formulaire = () => {
    const [results, setResult] = useState({presenceMairie:1,presenceSoiree:1})
    const [showHenne, setShowHenne] = useState(1)
    const [showSoiree, setShowSoiree] = useState(1)
    const [message, setMessage] = useState("")
    const [update, setUpdate] = useState(false)
   
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onChangeForm = (event) => {
        results[event.target.id] = event.target.value 
        if(results.presenceMairie==0){
            results.nombreAdulteHenne = 0
            results.nombreEnfantHenne = 0
            results.nombreCarHenne = 0
            setShowHenne(0)
        }else{
            setShowHenne(1)
        }
        if(results.presenceSoiree==0){
            setShowSoiree(0)
            results.nombreAdulteSoiree = 0
            results.nombreEnfantSoiree = 0
            results.nombreCarSoiree = 0
        }else{
            setShowSoiree(1)
        }
        setResult(results)
    }

    useEffect(()=>{
    }, [results.presenceSoiree,results.presenceHenne])

    const onSubmitForm = (event) =>{
        handleShow()
        event.preventDefault()
        setUpdate(true)
        axios.post("http://shanaetvalentin.com/add", {results}).then((r, err)=>{
            setUpdate(false)
            if(err) {
                console.log(err)
                
                setMessage("Erreur dans le formulaire.")
            }else{
                
                setMessage("Votre réponse a bien été enregistré.")
                
            }
        })

    }

    return (
        <Container id="reponse">
        <Row id="votre-reponse"  className="justify-content-around titre">
            <Col xs="auto" md="auto" data-aos="zoom-in"  data-aos-duration="2000">
                <div>Votre Réponse</div>
            </Col>
        </Row>
        <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        centered

      >
        <Modal.Body >
        <p style= {{fontStyle:"italic", color:"rgb(227, 161, 161)", textAlign:"center"}}>
                    {message}
                </p>
        </Modal.Body>
      </Modal>
        <Form onSubmit={onSubmitForm} id="form">
            <Row className="row-form justify-content-center">
                <Col md={6} sm={12}>
                {/* <Col md={6} sm={6}> */}
                    <FloatingLabel label="Prénom" className="">
                        <Form.Control type="text" onChange={onChangeForm} placeholder="Prénom" id="prenomFamille" required></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="row-form justify-content-center">
                <Col md={6} sm={12}>
                {/* <Col md={6}  sm={6}> */}
                    <FloatingLabel label="Nom" className="">
                        <Form.Control type="text" onChange={onChangeForm} placeholder="Nom" id="nomFamille"  required></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="row-form justify-content-center">
                {/* <Col md={6}  sm={6} > */}
                <Col md={6} sm={12}>
                    <FloatingLabel label="Numéro de téléphone" className="">
                        <Form.Control type="tel" onChange={onChangeForm} placeholder="Numéro de téléphone" id="telephoneFamille"  required></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="row-form justify-content-center">
                {/* <Col md={6}  sm={6}> */}
                <Col md={6} sm={12}>
                    <FloatingLabel label="Email" className="">
                        <Form.Control type="email" onChange={onChangeForm} placeholder="Email"id="mailFamille"  required></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="row-form justify-content-around">
                <Col md="auto" xs="auto">
                    Assisteront à la mairie :
                </Col>
            </Row>
            <Row className="row-form justify-content-around">
                <Col md="auto" xs="auto">
                    <Form.Check inline type="radio" onChange={onChangeForm} name="presenceMairie" id="presenceMairie" label="Présent(s) à la mairie" value={1}defaultChecked="1"></Form.Check>
                    <Form.Check inline type="radio" onChange={onChangeForm} name="presenceMairie" id="presenceMairie" label="Absent(s) à la mairie" value={0}></Form.Check>
                </Col>
            </Row>
            {/* {showHenne === 1 ?  */}
            <>
                <Row className="row-form justify-content-center">
                    <Col md={6} sm={12}>
                        <FloatingLabel label="Nombres d'adultes" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'adultes" id="nombreAdulteMairie" disabled={!showHenne} required={showHenne}></Form.Control>
                        </FloatingLabel>
                    </Col>
                {/* </Row>
                <Row className="row-form justify-content-center"> */}
                    {/* <Col md={6}  sm={12}>
                        <FloatingLabel  label="Nombres d'enfants" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'enfants" id="nombreEnfantHenne" disabled={!showHenne} required={showHenne}></Form.Control>
                        </FloatingLabel>
                    </Col> */}
                </Row> 
                
                {/* <Row className="row-form justify-content-center">
                    <Col md={12}  sm={12}>
                        <FloatingLabel label="Nombres de places dans le car" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres de places dans le car" disabled={!showHenne} required={showHenne} id="nombreCarHenne"></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row> */}
            </>
            {/* </> : <> <Row className="row-form justify-content-center">
                    <Col md={6} sm={12}>
                        <FloatingLabel label="Nombres d'adultes" className="">
                            <Form.Control type="text" onChange={onChangeForm} placeholder="Nombres d'adultes" id="nombreAdulteHenne" disabled value={0}></Form.Control>
                        </FloatingLabel>
                    </Col>
                {/* </Row>
                <Row className="row-form justify-content-center"> 
                    <Col md={6}  sm={12}>
                        <FloatingLabel  label="Nombres d'enfants" className="">
                            <Form.Control type="text" onChange={onChangeForm} placeholder="Nombres d'enfants" id="nombreEnfantHenne" disabled value={0}></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row>
                
                <Row className="row-form justify-content-center">
                    <Col md={12}  sm={12}>
                        <FloatingLabel label="Nombres de places dans le car" className="">
                            <Form.Control type="text" onChange={onChangeForm} placeholder="Nombres de places dans le car" id="nombreCarHenne" disabled value={0}></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row></> } */}
            <Row className="row-form justify-content-around">
                <Col md="auto" xs="auto">
                    Assisteront à la soirée :
                </Col>
            </Row>
            <Row className="row-form justify-content-around">
                <Col md="auto" xs="auto">
                    <Form.Check inline type="radio" onChange={onChangeForm} name="presenceSoiree" id="presenceSoiree" label="Présent(s) à la houppa" value={1} defaultChecked="1"></Form.Check>
                    <Form.Check inline type="radio" onChange={onChangeForm} name="presenceSoiree" id="presenceSoiree" label="Absent(s) à la houppa" value={0}></Form.Check>
                </Col>
            </Row>
            {/* {showSoiree == '1' ?  */}
            <>
                <Row className="row-form justify-content-center" >
                    <Col md={6} sm={12}>
                        <FloatingLabel label="Nombres d'adultes" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'adultes" id="nombreAdulteSoiree" disabled={!showSoiree} required={showSoiree}></Form.Control>
                        </FloatingLabel>
                    </Col>
                {/* </Row>
                <Row className="row-form justify-content-center"> */}
                    {/* <Col md={6}  sm={12}>
                        <FloatingLabel label="Nombres d'enfants" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'enfants" id="nombreEnfantSoiree" disabled={!showSoiree} required={showSoiree}></Form.Control>
                        </FloatingLabel>
                    </Col> */}
                </Row> 
                {/* <Row className="row-form justify-content-center">
                    <Col md={12}  sm={12}>
                        <FloatingLabel label="Nombres de places dans le car" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres de places dans le car" id="nombreCarSoiree" required={showSoiree} disabled={!showSoiree}></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row>  */}
            </>
            {/*: <>
            <Row className="row-form justify-content-center" >
                <Col md={6} sm={12}>
                    <FloatingLabel label="Nombres d'adultes" className="">
                        <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'adultes" id="nombreAdulteSoiree"  value={0}></Form.Control>
                    </FloatingLabel>
                </Col>
            {/* </Row>
            <Row className="row-form justify-content-center"> 
                <Col md={6}  sm={12}>
                    <FloatingLabel label="Nombres d'enfants" className="">
                        <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'enfants" id="nombreEnfantSoiree" disabled value={0}></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row> 
            <Row className="row-form justify-content-center">
                <Col md={12}  sm={12}>
                    <FloatingLabel label="Nombres de places dans le car" className="">
                        <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres de places dans le car" id="nombreCarSoiree" disabled value={0}></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row> 
        </>}*/}<br></br>
                <Row className="row-form justify-content-center">
                    <Col md={6} sm={12}>
                        <FloatingLabel label="Message pour les mariés">
                            <Form.Control  id="message" onChange={onChangeForm}
                            as="textarea"
                            placeholder="Message pour les mariés"
                            style={{ height: '100px' }}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <p style= {{fontStyle:"italic", color:"rgb(227, 161, 161)", textAlign:"center"}}>
                    {message}
                </p>
                <Row className="justify-content-center">
                    <Col md="auto" xs="auto">
                        <Button type="submit" disabled={update}>Envoyer</Button>
                    </Col>
                </Row>
        </Form><br></br>
        {/* <p style={{fontSize:8+"px", textAlign:"center", color:"gray", fontStyle:"italic"}}>Site web développé par <a style={{color:"lightslategray"}} href="https://www.linkedin.com/in/mbissor/">Melvin Bissor</a></p> */}

        </Container>
    )
}

export default Formulaire